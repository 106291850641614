import "../styles/home-section.css";

function HomeSection() {
  return (
    <div className="home-section" id="home">
      <div className="content">
        <div className="title">MetaVI</div>
        <div className="text">
          Uses A proprietary meta-learning architecture integrating <br />
          ensemble of state-of-the-art deep learning algorithms <br />
          combined with other ML & AI approaches.
          <br />
          Empowering end-users to easily train and deploy AI models by
          <br />
          themselves, saving AI engineering efforts for their organizations.
        </div>
      </div>
    </div>
  );
}

export default HomeSection;
