import bacgkroundVideo from "./background.mp4";
import backgroundImage from "./background.png";
import logo from "./logo.svg";
import symbol from "./symbol.svg";
import arrow from "./arrow.svg";
import returnArrow from "./return-arrow.svg";
import metaLearning from "./metaLearning.png";
import lifeCycle from "./lifeCycle.png";
import noCode from "./noCode.png";
import aiImpowered from "./AIEmpowered.png";
import arrowBullet from "./arrowBullet.svg";
import mail from "./mail.svg";
import phone from "./phone.svg";
import menu from "./menu.svg";
import back from "./back.svg";
import download from "./download.svg";

const assets = {
  backgroundImage,
  bacgkroundVideo,
  logo,
  symbol,
  arrow,
  returnArrow,
  metaLearning,
  lifeCycle,
  noCode,
  aiImpowered,
  arrowBullet,
  mail,
  phone,
  menu,
  back,
  download,
};

export default assets;
