import React, { useState, useEffect } from "react";
import "../styles/header.css";
import assets from "../assets";

const Header: React.FC<{
  pdf: null | string;
  closePDF: () => void;
}> = (props) => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // if (props.pdf !== null) return;
      const currentScrollPos = window.scrollY;
      setMenuVisible(false);
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, props.pdf]);

  return (
    <div className={`header ${visible ? "visible" : "hidden"}`}>
      <div className="logo">
        <img src={assets.logo} alt="logo" style={{ height: "55px" }} />
      </div>
      <div className="menu-btns">
        {props.pdf === null ? (
          <img
            src={assets.menu}
            alt="menu"
            onClick={() => {
              setMenuVisible(!menuVisible);
            }}
          />
        ) : (
          <>
            <img
              src={assets.download}
              alt="download"
              onClick={() => {
                props.pdf !== null && window.open(props.pdf, "_blank");
              }}
            />
            <img
              src={assets.back}
              alt="back"
              onClick={() => {
                props.closePDF();
              }}
            />
          </>
        )}
      </div>
      <div className={`links ${menuVisible ? " open" : ""}`}>
        {props.pdf === null ? (
          <>
            <a
              href="/#home"
              onClick={() => {
                setMenuVisible(false);
              }}
            >
              Home
            </a>
            <a
              href="/#how-it-works"
              onClick={() => {
                setMenuVisible(false);
              }}
            >
              How It Works
            </a>
            <a
              href="/#learn-more"
              onClick={() => {
                setMenuVisible(false);
              }}
            >
              Learn More
            </a>
            <a
              href="/#contact-us"
              onClick={() => {
                setMenuVisible(false);
              }}
            >
              Contact Us
            </a>
          </>
        ) : (
          <>
            <div
              onClick={() => {
                props.pdf !== null && window.open(props.pdf, "_blank");
              }}
            >
              Download
            </div>
            <div
              onClick={() => {
                props.closePDF();
                setMenuVisible(false);
              }}
            >
              Back
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
