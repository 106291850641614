import Header from "./components/Header";
import "./App.css";
import Background from "./components/Background";
import HomeSection from "./components/HomeSection";
import assets from "./assets";
import ReactGA from "react-ga4";
import React from "react";
// import { Alert } from "antd";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
// import { db } from "./Firebase";
// import { collection, addDoc } from "firebase/firestore";

// type AlertType = {
//   message: string;
//   type: "success" | "info" | "warning" | "error";
// };

function App() {
  const [pdf, setPdf] = React.useState<string | null>(null);
  const [numPages, setNumPages] = React.useState<number | null>(null);
  // const [alertMessage, setAlertMessage] = React.useState<AlertType | null>(
  //   null
  // );

  ReactGA.initialize("G-PEQY181NR3");
  ReactGA.gtag("config", "G-PEQY181NR3", {
    page_path: window.location.pathname,
  });

  React.useEffect(() => {
    window.onpopstate = () => {
      setPdf(null);
    };
  }, []);

  return (
    <>
      {/* {alertMessage ? (
        <Alert
          message={alertMessage.message}
          type={alertMessage.type}
          showIcon
          banner
          style={{
            margin: "10px",
            position: "fixed",
            bottom: "5%",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      ) : null} */}
      <div className="App">
        <Header
          pdf={pdf}
          closePDF={() => {
            setPdf(null);
          }}
        />
        {pdf ? (
          <Document
            file={pdf}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            className="pdf-view"
          >
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map((page) => (
                <Page
                  pageNumber={page}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  width={
                    window.innerWidth / window.innerHeight > 1.5
                      ? window.innerWidth / 1.5 - 20
                      : window.innerWidth - 20
                  }
                  loading=""
                />
              ))}
          </Document>
        ) : (
          <>
            <Background />
            <HomeSection />
            <div className="how-it-works" id="how-it-works">
              <div className="title">HOW IT WORKS</div>
              <div className="text">Visual Profiler Empowering AI Workflow</div>
              <div className="workflow">
                <div className="step">Anotate</div>
                <div className="arrow">
                  <img src={assets.arrow} alt="arrow" />
                </div>
                <div className="step">Create</div>
                <div className="arrow">
                  <img src={assets.arrow} alt="arrow" />
                </div>
                <div className="step">Analyze</div>
                <div className="arrow">
                  <img src={assets.arrow} alt="arrow" />
                </div>
                <div className="step">Run</div>
                <div className="arrow">
                  <img src={assets.arrow} alt="arrow" />
                </div>
                <div className="last-step">
                  <div className="step">Explore</div>
                  <div className="return-arrow">
                    <img src={assets.returnArrow} alt="return-arrow" />
                  </div>
                </div>
              </div>
              <div className="title">The Process</div>
              <div className="process-steps">
                <div className="step">
                  <div className="sub-title">Annotate</div>
                  <div className="text">
                    Simultaneous annotations by unlimited multiple VP Analyst
                    users. Annotation candidates are stored for VP Creator users
                    to create or improve Profiles.
                  </div>
                </div>
                <div className="step">
                  <div className="sub-title">Create</div>
                  <div className="text">
                    A complete cognitive AI Machine Learning engine, designed
                    for the analyst, to the create object detection algorithms
                    (“Profile”) in Aerial/Satellite imagery.
                  </div>
                </div>
                <div className="step">
                  <div className="sub-title">Analyze</div>
                  <div className="text">
                    Estimate Profiles performance and analyze the best trade off
                    between MDs and FAs for finding the optimal running
                    parameters.
                  </div>
                </div>
                <div className="step">
                  <div className="sub-title">Run</div>
                  <div className="text">
                    Run a Profile on single/multiple ROIs and images. Manage
                    jobs and their progress.
                  </div>
                </div>
                <div className="step">
                  <div className="sub-title">Explore</div>
                  <div className="text">
                    Multi-user analysis tool for rapidly reviewing and
                    validating the ranked results. Manage detections to
                    geo-referenced interoperable formats.
                  </div>
                </div>
              </div>
            </div>
            <div className="images-section">
              <div className="image">
                <div className="text">META LEARNING</div>
                <img src={assets.metaLearning} alt="metaLearning" />
              </div>
              <div className="image">
                <div className="text">ACCELERATING AI LIFE CYCLE</div>
                <img src={assets.lifeCycle} alt="lifeCycle" />
              </div>
              <div className="image">
                <div className="text">NO CODE PLATFORM</div>
                <img src={assets.noCode} alt="noCode" />
              </div>
            </div>
            <div className="learn-more" id="learn-more">
              <div className="symbol">
                <img src={assets.symbol} alt="symbol" />
              </div>
              <div className="title">LEARN MORE</div>
              <span className="text">
                Visual Profiler is an AI&ML revolutionary software for
                detecting, classifying, interpreting, profiling, and managing
                objects in aerial and satellite images. The Visual Profiler is a
                proprietary technology that integrates state-of-the-art Deep
                Learning schemes with other machine learning approaches to
                maximize performance and can uniquely distinguish and categorize
                different objects much as humans do cognitively.
              </span>
              <span className="text">
                Whether it is a wide area or specific sites, the Visual Profiler
                system enables organizations to cope, efficiently and simply,
                with the imagery overload by automatically detecting and
                classifying objects of interest, to streamline the analysis
                workflow, thus providing monitoring and alerting to changes or
                deviations from routine.
              </span>
              <button
                className="open-btn"
                onClick={() => {
                  ReactGA.event({
                    category: "Open MetaVI",
                    action: "Click",
                  });

                  // Add current page to the history if press back button go back to the previous page with setPdf(null)
                  window.history.pushState({ pdf }, "MetaVI", "/#learn-more");

                  setPdf("vp1.pdf");
                }}
              >
                Open Visual Profiler Brochure
              </button>
            </div>
            <div className="ai-empowered">
              <div className="text">
                A novel AI approach enabling analysts a new level of insights by
                simplifying the accessibility of state-of-the-art No-Code
                complete visual AI tools.
              </div>
              <div className="stripe">
                <img src={assets.aiImpowered} alt="aiImpowered" />
              </div>
              <div className="text">
                Empowering end-users to easily train and deploy AI models by
                themselves, saving AI engineering efforts for their
                organizations.
              </div>
            </div>
            <div className="key-strength">
              <div className="title">Visual Profiler - Key Strengths</div>
              <div className="strengths">
                <div className="strength">
                  <div className="title">
                    <img src={assets.arrowBullet} alt="arrowBullet" />
                    <span>Meta Learning</span>
                  </div>
                  <div className="text">
                    A complete cognitive AI Machine Learning engine, designed
                    for the analyst.
                  </div>
                </div>
                <div className="strength">
                  <div className="title">
                    <img src={assets.arrowBullet} alt="arrowBullet" />
                    <span>Resiliency</span>
                  </div>
                  <div className="text">
                    Resiliency and robustness to unforeseen changes such as
                    lighting and seasonal effects.
                  </div>
                </div>
                <div className="strength">
                  <div className="title">
                    <img src={assets.arrowBullet} alt="arrowBullet" />
                    <span>AI and ML</span>
                  </div>
                  <div className="text">
                    An ensemble of AI and ML, which is automatically selected
                    for optimal performance.
                  </div>
                </div>
                <div className="strength">
                  <div className="title">
                    <img src={assets.arrowBullet} alt="arrowBullet" />
                    <span>Flexibility</span>
                  </div>
                  <div className="text">
                    Unlimited object definition and profiling flexibility.
                  </div>
                </div>
                <div className="strength">
                  <div className="title">
                    <img src={assets.arrowBullet} alt="arrowBullet" />
                    <span>Operational Needs</span>
                  </div>
                  <div className="text">
                    Small amount of training examples, Usually 100’s up to
                    1000’s to rapidly adjust to new operational needs.
                  </div>
                </div>
                <div className="strength">
                  <div className="title">
                    <img src={assets.arrowBullet} alt="arrowBullet" />
                    <span>Adjusts</span>
                  </div>
                  <div className="text">Adjusts to almost any sensor.</div>
                </div>
              </div>
            </div>
            <div className="contact-us" id="contact-us">
              <div className="title">Contact Us</div>
              <div className="buttons">
                <button>
                  <img src={assets.phone} alt="phone" />
                  <a
                    onClick={() => {
                      ReactGA.event({
                        category: "Contact Us",
                        action: "Call",
                      });
                    }}
                    href="tel:+1 (703) 879-0060"
                  >
                    +1 (703) 879-0060
                  </a>
                </button>
                <button>
                  <img src={assets.mail} alt="mail" />
                  <a
                    href="mailto:info@metavi.ai"
                    onClick={() => {
                      ReactGA.event({
                        category: "Contact Us",
                        action: "Email",
                      });
                    }}
                  >
                    info@metavi.ai
                  </a>
                </button>
              </div>
              {/* <div className="early-access">
                <input type="email" placeholder="Email" />
                <button
                  onClick={async () => {
                    const email = document.querySelector(
                      "input[type=email]"
                    ) as HTMLInputElement;
                    const emailRegex =
                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                    const isValidEmail = emailRegex.test(email.value);
                    if (isValidEmail) {
                      await fetch("/mail.php?email=" + email.value);
                      email.value = "";
                      setAlertMessage({
                        message: "Thank you for your interest!",
                        type: "success",
                      });
                    } else {
                      setAlertMessage({
                        message: "Please enter a valid email",
                        type: "error",
                      });
                    }
                    setTimeout(() => {
                      setAlertMessage(null);
                    }, 2000);
                  }}
                >
                  Get early access
                </button>
              </div> */}
            </div>
            <div className="location">
              1818 Library Street, Suite #500, Reston, VA 20190
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default App;
