import React from "react";
import "../styles/background.css";
import assets from "../assets";

class Background extends React.Component {
  render() {
    return (
      <video
        playsInline
        className="videoBG"
        poster={assets.backgroundImage}
        disablePictureInPicture
        autoPlay
        muted
        loop
      >
        <source src={assets.bacgkroundVideo} type="video/mp4" />
      </video>
    );
  }
}

export default Background;
